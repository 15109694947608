<template>
  <div class="p-grid">
    <div class="p-col-12">
      <div class="card card-w-title">
        <div class="manager-title">{{ title }}</div>
        <AppoinmentCalendar 
          :key="componentKey"
          :model="dataModel"
        />
      </div>
    </div>
  </div>
</template>

<script>
import DataServices from '@/core/DataServices'
import AppoinmentCalendar from './calendar'
export default {
  components: {
    AppoinmentCalendar,
  },
  data() {
    return {
      modelName: '',
      dataModel: null,
      title: '',
      componentKey: 0,
      role: {
        view: false,
        add: false,
        edit: false,
        delete: false,
      }
    }
  },
  mounted () {
    this.initParams();
  }
  ,
  async created() {
    this.modelName = this.$route.meta.model;
    this.dataModel = DataServices.getModel(this.modelName);
    //console.log('this.dataModel:',this.dataModel);
    this.title = this.dataModel.name
    this.$store.commit('setLoading', true);
    if(this.$route.params.reload) {
      window.location.reload();
    }
    var arr_role = await this.$CoreService.checkPermission(this.$constants.KEY_MENU.LIST_APPOINMENTS);
    if (arr_role.indexOf(this.$constants.ROLE.VIEW) < 0){
      this.$router.push({path: this.$constants.URL_ERR.FOBIDDEN});
    }
    // console.log("arr_role:" ,arr_role)
    this.role.add = (arr_role.indexOf(this.$constants.ROLE.ADD) < 0) ? false:true;
    this.role.edit = (arr_role.indexOf(this.$constants.ROLE.EDIT) < 0) ? false:true;
    this.role.delete = (arr_role.indexOf(this.$constants.ROLE.DELETE) < 0) ? false:true;
  },
  watch: {
    '$route': 'initParams'
  },
  methods: {
    initParams() {
      this.$store.commit('setLoading', true);
      this.modelName = this.$route.meta.model;
      this.dataModel = DataServices.getModel(this.modelName);
      this.title = this.dataModel.label;
    }
  },

}
</script>
<style lang="css">
.p-calendar .p-datepicker { width: 280px; padding: 0px; padding-top: 5px;}
.p-calendar .p-datepicker td, .p-calendar .p-datepicker td a {padding: 0px !important;}
.p-calendar .p-datepicker td span {padding: 0px !important; width: 30px !important; height: 30px !important;}
.p-datepicker .p-datepicker-header{padding: 5px !important;}
.p-button-warning .p-button-label{
  color: #1D76BC;
}
.p-button-warning .pi-times{
  color: #1D76BC;
}
</style>